import type { CSSProperties, FC } from 'react'
import { memo, useCallback, useEffect, useRef, useState } from 'react'

import { Skeleton } from 'antd'
import classNames from 'classnames'

import { Select } from 'common/components/Select/Select'
import { Show } from 'common/components/Show/Show'
import { ALT_CONSTANTS } from 'common/constants/altConstants'
import { BUTTON_MODIFIER } from 'common/constants/buttonConstants'
import type { ISelect } from 'common/interfaces/ISelect'
import { UtilService } from 'common/services/utilService'

// I suppress next line with eslint-disable-next-line because i use dynamic class
import styles from './suffixSelect.module.scss'

const { capitalizeFirstLetter } = UtilService

interface IProps extends ISelect {
  icon: string
  title?: string
  isLoading?: boolean
  modifier?: string
  customStyles?: CSSProperties
}

export const SuffixSelect: FC<IProps> = memo(
  ({
    icon,
    title,
    disabled,
    isLoading,
    modifier = BUTTON_MODIFIER.DEFAULT,
    customStyles,
    ...rest
  }) => {
    const [open, setOpen] = useState(false)
    const openedByTouch = useRef(false)

    useEffect(() => {
      const closeSelect = () => setOpen(false)
      window.addEventListener('scroll', closeSelect)
      return () => window.removeEventListener('scroll', closeSelect)
    }, [])

    const handleOpenDropdown = useCallback(() => {
      setOpen(true)
      openedByTouch.current = true
    }, [])

    const handleDropdownVisibleChange = useCallback((visible: boolean) => {
      if (!openedByTouch.current) {
        setOpen(visible)
      }
      if (!visible) {
        openedByTouch.current = false
      }
    }, [])

    const handleSelect = useCallback(
      (value: any, option: any) => {
        if (rest.onSelect) {
          rest.onSelect(value, option)
        }
        setOpen(false)
        openedByTouch.current = false
      },
      [rest.onSelect],
    )

    return (
      <div className={classNames(styles.parent, { [styles.parentDisabled]: disabled })}>
        <span className={styles.parentLocation}>
          <Show when={!!title}>
            <h3
              className={classNames(
                styles.parentTitle,
                styles[`parentTitle${capitalizeFirstLetter(modifier)}`],
              )}>
              {title}
            </h3>
          </Show>
          <span
            className={classNames(
              styles.parentSelect,
              styles[`parentSelect${capitalizeFirstLetter(modifier)}`],
            )}
            style={customStyles}>
            <img
              draggable={false}
              src={icon}
              className={styles.parentSuffix}
              alt={ALT_CONSTANTS.LOCATION_PIN}
            />
            <Show
              when={!isLoading}
              fallback={
                <Skeleton active className={styles.parentSkeleton} paragraph={{ rows: 0 }} />
              }>
              <Select
                {...rest}
                onClick={(e) => e.stopPropagation()}
                open={open}
                onTouchStart={handleOpenDropdown}
                onDropdownVisibleChange={handleDropdownVisibleChange}
                onSelect={handleSelect}
                disabled={disabled}
                popupClassName={styles.parentDropdown}
              />
            </Show>
          </span>
        </span>
      </div>
    )
  },
)
